<template>
  <div class="h-32 w-32 mx-auto">
    <router-link
      to="/pre/interview"
      data-v-step="0"
      :class="nextPage ? '' : 'invisible opacity-0 transition-all ease-in'"
      class="h-32 w-32 hover:textcol hover:bg-white border border-white text-white transition-all ease-out rounded-full flex items-center justify-center mx-auto"
    >
      <h2 class="text-lg">Başla</h2>
      <tour :steps="steps" />
    </router-link>
  </div>
</template>

<script>
import tour from "@/components/tour.vue";

export default {
  name: "next-btn",
  props: ["nextPage"],
  components: {
    tour,
  },
  data() {
    return {
      steps: [
        {
          target: '[data-v-step="0"]',
          header: {
            title: "Ön Görüşmeyi başlat",
          },
          content:
            'Ön görüşmeyi başlatmak için lütfen "BAŞLA" butonuna tıklayın.',
          params: {
            placement: "bottom",
          },
        },
      ],
    };
  },
  methods: {},
};
</script>
